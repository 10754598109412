<template>
  <div></div>
</template>
<script>
import error from '@/mixins/error';
import AccountService from '@/services/AccountService';

export default {
  name: 'ResendVerificationIndex',
  mixins: [error],
  data() {
    return {
      errorMessage: null,
    }
  },
  mounted() {
    this.resendEmailVerification();
  },
  methods: {
    resendEmailVerification() {
      this.$swal.fire({
        title: 'Reenviar Correo de Verificación',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        preConfirm: async (email) => {
          try {
            return await AccountService.resendEmailVerification({ email });
          } catch (e) {
            this.errorMessage = this.getErrorMessage(e);
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'landing.index' });
          this.$swal(
            'Verificación Reenviada',
            'Por favor, verifica tu correo electrónico',
            'success',
          );
        }
      });
    },
  },
}
</script>
